import email from '../assets/email.png';
import bg1 from '../assets/Bg1.png';
import bg2 from '../assets/Bg2.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';


export default {
  email,
  bg1,
  bg2,
  circle,
  logo,
};